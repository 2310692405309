import { useRef, useEffect } from "react";
import Media from "../../utils/Media/Media";
import styles from "./MultiMediaBlock.module.scss";
import classNames from "classnames";
import { useState } from "react";
import { ImageProps } from "components/base/Image/Image";
import { VideoProps } from "components/shared/Video/Video";
import useExpand from "src/utils/useExpand";
import useOnResize from "src/utils/useOnResize";

export interface MultiMediaBlockProps {
  video?: VideoProps;
  image?: ImageProps;
  byline?: string;
  description?: string;
  expandButtonText?: string;
  closeButtonText?: string;
  isLargeMedia?: boolean;
  onDarkBackground?: boolean;
}

const MultiMediaBlock: React.FC<MultiMediaBlockProps> = ({
  video,
  image,
  byline,
  description,
  expandButtonText,
  closeButtonText,
  onDarkBackground,
}) => {
  const [expandedMessageHeight, setExpandedMessageHeight] = useState<
    undefined | number
  >(undefined);
  const [isTruncated, setIsTruncated] = useState<boolean>(false);
  const textRef = useRef<(() => void) | null>(null);

  const handleExpand = (expandedHeight: number) => {
    setExpandedMessageHeight(expandedHeight);
  };
  const handleMinimized = (minimizedHeight: number) => {
    setExpandedMessageHeight(minimizedHeight);
  };

  const { textRefFunction, isExpanded, setIsExpanded } = useExpand(
    handleExpand,
    handleMinimized
  );

  const updatedTruncatedState = () => {
    if (textRef.current) {
      setIsTruncated(
        //@ts-ignore
        textRef.current.scrollHeight > textRef.current.clientHeight
      );
    }
  };

  useEffect(() => {
    updatedTruncatedState();
  }, []);

  useOnResize(() => {
    updatedTruncatedState();
  });

  return (
    <div
      className={classNames(styles.multiMediaBlock, {
        [styles.dark]: onDarkBackground === true,
      })}
    >
      {(video || image) && (
        <Media className={styles.media} video={video} image={image} />
      )}
      {(description || byline) && 
        <div
          className={classNames(styles.imageText, {
            [styles.isExpanded]: isExpanded,
            [styles.isCollapsed]: !isExpanded,
          })}
          ref={element => {
            textRefFunction(element);
            // @ts-ignore
            textRef.current = element;
          }}
          // @ts-ignore: CSS variable
          style={
            expandedMessageHeight && {
              "--maxHeight": expandedMessageHeight + "px",
            }
          }
        >
          {description && <p className={styles.description}>{description}</p>}
          {byline && <p className={styles.byline}>{byline}</p>}
        </div>
      }
      {expandButtonText && closeButtonText && isTruncated && (
        <button
          className={styles.expandButton}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? closeButtonText : expandButtonText}
        </button>
      )}
    </div>
  );
};

export default MultiMediaBlock;
