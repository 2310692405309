import Image from "components/base/Image/Image";
import { FC } from "react";

interface VideoThumbnailProps {
  url: string;
}

function getYouTubeThumbnail(url: string) {
  const regExp = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/;
  const match = url.match(regExp);

  const videoId = match && match[2].length === 11 ? match[2] : null;
  if (!videoId) {
    console.error("Unable to extract Video ID from YouTube URL");
    return "";
  }

  return `https://img.youtube.com/vi/${videoId}/0.jpg`;
}

function getVimeoThumbnail(url: string) {
  const regExp = /vimeo\.com\/(?:video\/)?(\d+)/;
  const match = url.match(regExp);

  const videoId = match ? match[1] : null;
  if (!videoId) {
    console.error("Unable to extract Video ID from Vimeo URL");
    return "";
  }

  return `https://i.vimeocdn.com/video/${videoId}_1280.jpg`;
}

const VideoThumbnail: FC<VideoThumbnailProps> = ({ url }) => {
  const thumbnailUrl = url.includes("youtube")
    ? getYouTubeThumbnail(url)
    : getVimeoThumbnail(url);

  return <Image src={thumbnailUrl} alt="Video Thumbnail" />;
};

export default VideoThumbnail;
