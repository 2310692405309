import styles from "./LinkListWithIconsBlock.module.scss";
import classNames from "classnames";
import LinkItemWithIcon, {
  LinkItemWithIconProps,
} from "components/base/LinkItemWithIcon/LinkItemWithIcon";

export interface LinkListWithIconsBlockProps {
  heading: string;
  list: LinkItemWithIconProps[];
  theme?: "white" | "gray";
}

const LinkListWithIconsBlock: React.FC<LinkListWithIconsBlockProps> = ({
  heading,
  list,
  theme = "white",
}) => {
  return (
    <div className={classNames(styles.linkListWithIcons, styles[theme])}>
      <h2>{heading}</h2>
      {list?.length > 0 && (
        <ul>
          {list.map(item => (
            <LinkItemWithIcon {...item} />
          ))}
        </ul>
      )}
    </div>
  );
};

export default LinkListWithIconsBlock;
