import { FC } from "react";
import cn from "classnames";
import styles from "./Icon.module.scss";
// @ts-ignore
const req = require.context(
  "!!raw-loader!../../../assets/icons",
  true,
  /\.svg$/
);

const icons = req.keys().reduce((icons: any, filename: string) => {
  const id = filename.replace("./", "").replace(".svg", "");
  const svgString = req(filename).default;

  const SvgComponent = ({ className, ...attr }: { className: string }) => (
    <span
      className={className}
      dangerouslySetInnerHTML={{ __html: svgString }}
      {...attr}
    />
  );
  return { ...icons, [id]: SvgComponent };
}, {});

export const iconNames = Object.keys(icons);

interface IIconProps {
  name: string;
  className?: string;
  role?: string;
}

const Icon: FC<IIconProps> = ({ name, className, ...attr }) => {
  const Icon = icons[name];
  if (!Icon) return null;
  return <Icon className={cn(styles.icon, className)} {...attr} />;
};

export default Icon;
