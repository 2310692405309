import { ReactComponentPropsBase } from "../../../base-props/ReactComponentPropsBase";

import { DynamicBlock } from "SiteComponents";
import * as Components from "SiteComponents";
import { IReactComponentProps } from "src/base-props/IReactComponentProps";
import { DynamicBlockProps } from "components/utils/DynamicBlock/DynamicBlock";
import LayoutBlockGridPropsProxy, { GridProps } from "./LayoutBlockLayoutProps";
import GridContainer, { GridContainerStyleProps } from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";

export interface Blocks extends DynamicBlockProps {
  gridItemProps?: any;
}
export interface LayoutBlockProps extends ReactComponentPropsBase {
  componentName: keyof typeof Components;
  component?: IReactComponentProps;
  additionalGridContainerProps?: GridContainerStyleProps;
}

const LayoutBlock: React.FC<LayoutBlockProps> = ({
  component,
  componentName,
  additionalGridContainerProps,
}) => {
  const gridProps: GridProps | undefined =
    LayoutBlockGridPropsProxy[componentName];
  if (gridProps && component) {
    const { gridContainerProps, gridItemProps } = gridProps;
    return (
      <GridContainer {...gridContainerProps} {...additionalGridContainerProps}>
        <GridItem {...gridItemProps}>
          <DynamicBlock component={component} componentName={componentName} />
        </GridItem>
      </GridContainer>
    );
  }
  return null;
};

export default LayoutBlock;
