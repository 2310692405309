import classNames from "classnames";
import styles from "./AlertBlock.module.scss";
import Icon from "src/components/utils/Icon/Icon"
import RichText from "src/components/base/RichText/RichText"
import { useCallback, useState } from "react";
import useWindowSize from "src/utils/useWindowSize";
import { setTabIndexes } from "src/utils/setTabIndexes";

export interface AlertBlockProps {
  title?: string;
  message: string;
  alertType: "informative" | "positive" | "negative" | "warning";
  showMoreText: string;
  showLessText: string;
}

const AlertBlock = ({
  title,
  message,
  alertType,
  showMoreText,
  showLessText,
}: AlertBlockProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [nodeHeight, setNodeHeight] = useState(0);

  const breakpointMobile = 768;
  const messageHeightDesktop = 21;
  const messageHeightMobile = 42;

  const isDesktop = useWindowSize().width > breakpointMobile;

  const expandMessage = useCallback(
    (node: HTMLDivElement | null) => {
      if (node !== null) {
        setNodeHeight(node.scrollHeight);
        if (isExpanded) {
          setTabIndexes(node, "0");
          node.style.maxHeight = `${node.scrollHeight}px`;
        } else {
          setTabIndexes(node, "-1");
          node.style.maxHeight =
            window.innerWidth > breakpointMobile
              ? `${messageHeightDesktop}px`
              : `${messageHeightMobile}px`;
        }
      }
    },
    [isExpanded]
  );

  return (
    <div className={classNames(styles.alertBlock, styles[alertType])}>
      <Icon className={styles.icon} name={`alert-block-${alertType}`} />
      <div className={styles.message}>
        {title && <h2>{title}</h2>}
        <RichText
          className={classNames(styles.richText, {
            [styles.isExpanded]: isExpanded,
          })}
          refFunction={expandMessage}
          html={message}
        />
        {nodeHeight >
          (isDesktop ? messageHeightDesktop : messageHeightMobile) && (
          <button
            className={styles.expandButton}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? showLessText : showMoreText}
          </button>
        )}
      </div>
    </div>
  );
};

export default AlertBlock;
