import React from "react";
import styles from "./WithActionClasses.module.scss";
import { useTheme } from "src/utils/useTheme";
import cn from "classnames";

export interface WithActionClassesProps {
  className?: string;
  theme?: "primary" | "secondary" | "tertiary";
  size?: "large" | "medium" | "small";
  disabled?: boolean;
}

const WithActionClasses = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  type Props = P & WithActionClassesProps;
  const ComponentWithActionClasses: React.FC<Props> = ({
    theme = "primary",
    size = "medium",
    className,
    ...props
  }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const contextTheme = useTheme();

    const classNames = cn(styles.action, className, {
      [styles[theme]]: theme,
      [styles[size]]: size,
      [styles.hasDarkBackground]: contextTheme === "dark",
      [styles.disabled]: props?.disabled,
    });
    return <WrappedComponent {...(props as P)} className={classNames} />;
  };

  return ComponentWithActionClasses;
};

export default WithActionClasses;
