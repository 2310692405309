import React, { useState, useEffect, useMemo, useRef } from "react";
import Cookies from "js-cookie";
import { ReactComponentPropsBase } from "../../../base-props/ReactComponentPropsBase";
import styles from "./AlertBanner.module.scss";
import IconMS from "../../utils/IconMS/IconMS";
import Icon from "../../utils/Icon/Icon";
import RichText from "../../base/RichText/RichText";
import BlockContainer from "components/layout/BlockContainer/BlockContainer";
import { useTheme } from "src/utils/useTheme";
import classNames from "classnames";
import useExpand from "src/utils/useExpand";

export interface AlertBannerProps extends ReactComponentPropsBase {
  message: string;
  ariaLabelCloseIcon: string;
  expiresInDays?: number;
  setBannerHeight?: (height: number) => void;
  iconName: "informative" | "warning";
  expandButtonText?: string;
  closeButtonText?: string;
  // This is purely for Storybook - BE can ignore
  displayStorybook?: boolean;
}
const djb2Hash = (str: string) => {
  let hash = 5381;
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i);
    hash = (hash * 33) ^ charCode;
  }
  return `alert-banner-${hash >>> 0}`;
};

const AlertBanner: React.FC<AlertBannerProps> = ({
  message,
  ariaLabelCloseIcon,
  setBannerHeight = () => null,
  iconName,
  expandButtonText,
  closeButtonText,
  displayStorybook,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [storybookToggle, setStorybookToogle] = useState(displayStorybook);
  const cookieName = useMemo(() => djb2Hash(message), [message]);
  const theme = useTheme();
  const [update, setUpdate] = useState(0);

  const [richTextHeight, setRichTextHeight] = useState(0);

  const alertBannerRef = useRef<HTMLDivElement>(null);

  const getBannerHeightWithoutRichText = () => {
    const alertBannerHeight = alertBannerRef.current?.offsetHeight || 0;
    return alertBannerHeight - richTextHeight;
  };

  const handleExpand = (expandedHeight: number) => {
    setRichTextHeight(expandedHeight);
    setBannerHeight(getBannerHeightWithoutRichText() + expandedHeight);
  };

  const handleMinimized = (minimizedHeight: number) => {
    setRichTextHeight(minimizedHeight);
    setBannerHeight(alertBannerRef.current?.offsetHeight || 0);
  };

  const { textRefFunction, isExpanded, setIsExpanded } = useExpand(
    handleExpand,
    handleMinimized
  );

  useEffect(() => {
    const isClosed = Cookies.get(cookieName);
    if (isClosed !== "true") {
      setIsVisible(true);
    }
  }, [cookieName]);

  useEffect(() => {
    if (!alertBannerRef.current) return;

    if (update === 0) {
      setUpdate(update + 1);
      return;
    }
    setBannerHeight(alertBannerRef.current.offsetHeight);
  }, [update]);

  const handleClose = () => {
    setIsVisible(false);
    setBannerHeight(0);
    setStorybookToogle(!storybookToggle);
    Cookies.set(cookieName, "true", { expires: 30 });
  };
  if (!isVisible && !storybookToggle) return null;

  return (
    <div
      className={classNames(styles.alertBanner, {
        [styles.hasDarkBackground]: ["green", "dark"].includes(theme),
      })}
      ref={alertBannerRef}
    >
      <BlockContainer className={styles.alertBlockContainer}>
        <div className={styles.message}>
          <div className={styles.richTextContainer}>
            <Icon
              className={styles[`${iconName}Icon`]}
              name={`banner-${iconName}`}
            />
            <RichText
              className={classNames(styles.richText, {
                [styles.isExpanded]: isExpanded,
                [styles.isCollapsed]: !isExpanded,
              })}
              refFunction={textRefFunction}
              // @ts-ignore: CSS variable
              style={
                richTextHeight ? { "--maxHeight": richTextHeight + "px" } : null
              }
              html={message}
            />
          </div>
          {expandButtonText && closeButtonText && (
            <button
              className={styles.expandButton}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? closeButtonText : expandButtonText}
            </button>
          )}
        </div>
        <button
          className={styles.closeButton}
          aria-label={ariaLabelCloseIcon}
          onClick={handleClose}
        >
          <IconMS name="close" />
        </button>
      </BlockContainer>
    </div>
  );
};

export default AlertBanner;
