import React, { useEffect, useState } from "react";
import styles from "./PaginationBlock.module.scss";
import classNames from "classnames";
import Icon from "src/components/utils/Icon/Icon";
import useWindowSize from "src/utils/useWindowSize";

export interface PaginationBlockProps {
  currentPage: number;
  itemLabel?: (num: number) => string;
  nextLabel: string;
  onChange: (page: number) => void;
  previousLabel: string;
  totalPages: number;
  navAriaLabel?: string;
}

const PaginationBlock: React.FC<PaginationBlockProps> = ({
  currentPage,
  itemLabel = num => `Side ${num}`,
  nextLabel,
  onChange,
  previousLabel,
  totalPages,
  navAriaLabel = "Paginering",
}) => {
  const [maxPageButtons, setMaxPageButtons] = useState(4);

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      onChange(page);
    }
  };

  const isDesktop = useWindowSize().width > 768;

  useEffect(() => {
    setMaxPageButtons(isDesktop ? 5 : 4);
  }, [isDesktop]);

  const { pages, showStartEllipsis, showEndEllipsis } = (() => {
    const showStartEllipsis = totalPages > maxPageButtons && currentPage > 3;
    const showEndEllipsis =
      totalPages > maxPageButtons && currentPage < totalPages - 3;

    let startPage = Math.max(currentPage - 1, 1);

    if (totalPages - startPage < maxPageButtons) {
      startPage = Math.max(totalPages - maxPageButtons + 2, 1);
    }

    let shownPages = maxPageButtons;
    if (showStartEllipsis) shownPages--;
    if (showEndEllipsis) shownPages--;

    const pages = Array.from(
      { length: shownPages },
      (_, idx) => startPage + idx
    );

    return { pages, showStartEllipsis, showEndEllipsis };
  })();

  return (
    <nav aria-label={navAriaLabel} className={styles.paginationBlock}>
      <button
        disabled={currentPage === 1}
        className={classNames(styles.prevButton, {
          [styles.hide]: currentPage === 1,
        })}
        onClick={() => handlePageChange(currentPage - 1)}
      >
        <Icon name="chevron-left" />
        <span className={styles.navButtonLabel}> {previousLabel}</span>
      </button>
      {showStartEllipsis && (
        <>
          <button
            aria-label={itemLabel(1)}
            className={classNames(styles.pageButton, {
              [styles.current]: 1 === currentPage,
            })}
            onClick={() => handlePageChange(1)}
          >
            1
          </button>
          <span className={styles.ellipsis}>...</span>
        </>
      )}
      {pages.map(page => (
        <button
          key={page}
          aria-label={itemLabel(page)}
          className={classNames(styles.pageButton, {
            [styles.current]: page === currentPage,
          })}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </button>
      ))}
      {showEndEllipsis && (
        <>
          <span className={styles.ellipsis}>...</span>
          <button
            aria-label={itemLabel(totalPages)}
            className={classNames(styles.pageButton, {
              [styles.current]: totalPages === currentPage,
            })}
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </button>
        </>
      )}
      {currentPage < totalPages && (
        <button
          className={classNames(styles.nextButton, {
            [styles.hide]: currentPage === totalPages,
          })}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <span className={styles.navButtonLabel}>{nextLabel}</span>
          <Icon name="chevron-right" />
        </button>
      )}
    </nav>
  );
};

export default PaginationBlock;
