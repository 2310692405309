import styles from "./CtaBlock.module.scss";
import classNames from "classnames";
import Image, { ImageProps } from "../../../components/base/Image/Image";
import { Button, CTA } from "../../../MIKA.Components";
import useSelectTheme from "../../../utils/useSelectTheme";
import MouseLink from "components/utils/MouseLink/MouseLink";
import Icon from "../../../components/utils/Icon/Icon";
import { LinkProps } from "src/base-props/LinkProps";

export interface CtaBlockProps extends LinkProps {
  buttonText: string;
  heading: string;
  image?: ImageProps;
  imageOnLeft?: boolean;
  showArrow?: boolean;
  theme?: "green" | "gray" | "white";
}

const CtaBlock: React.FC<CtaBlockProps> = ({
  buttonText,
  heading,
  image,
  imageOnLeft,
  showArrow,
  text,
  theme = "gray",
  url,
}) => {
  const selectedTheme = useSelectTheme(theme, "blue");
  const selectedButtonTheme = useSelectTheme("mika-primary", "mika-blue");
  if (text && url) {
    return (
      <MouseLink url={url}>
        <div className={classNames(styles.ctaBlock, styles[selectedTheme])}>
          <div
            className={classNames(styles.imageContainer, {
              [styles.leftImage]: imageOnLeft,
            })}
          >
            {image && <Image src={image.src} alt={image.alt} />}
          </div>

          <div className={styles.textContent}>
            <div className={styles.tag}>{heading}</div>
            <h2 className={styles.heading}>{text}</h2>
            <div className={styles.buttonContainer}>
              <CTA
                url={url}
                text={buttonText}
                aria-label={heading ? heading : buttonText}
                showIcon={showArrow}
              />
            </div>
          </div>
        </div>
      </MouseLink>
    );
  }
  return null;
};

export default CtaBlock;
