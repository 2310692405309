import React from "react";
import { ReactComponentPropsBase } from "../../base-props/ReactComponentPropsBase";
import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";

export interface NoraStartPageProps extends ReactComponentPropsBase {
  contentArea: ContentAreaProps;
}

const NoraStartPage: React.FC<NoraStartPageProps> = ({ contentArea }) => {
  return (
    <main className="noraStartPage">
      {contentArea && <ContentArea {...contentArea} />}
    </main>
  );
};

export default NoraStartPage;
