import styles from "./LinkCardLargeBlock.module.scss";
import Icon from "../../utils/Icon/Icon";
import classNames from "classnames";
import useSelectTheme from "../../../utils/useSelectTheme";
import MouseLink from "components/utils/MouseLink/MouseLink";
import { LinkProps } from "src/base-props/LinkProps";

export interface LinkCardLargeBlockProps extends LinkProps {
  icon?: string;
  intro?: string;
}

const LinkCardLargeBlock: React.FC<LinkCardLargeBlockProps> = ({
  text,
  icon,
  intro,
  url,
}) => {
  const selectedTheme = useSelectTheme("", "blue");
  if (url && text) {
    return (
      <MouseLink
        className={classNames(styles.linkCardLarge, styles[selectedTheme])}
        url={url}
      >
        <div className={styles.titleContainer}>
          <a href={url}>
            <h3>{text}</h3>
          </a>
          {icon && <Icon className={styles.icon} name={icon} aria-hidden />}
        </div>
        {intro && <p>{intro}</p>}
      </MouseLink>
    );
  }
  return null;
};

export default LinkCardLargeBlock;
