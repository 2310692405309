import { FC } from "react";
import cn from "classnames";

//NOTE: this is identical to the one in the components/utils folder. Can we delete this one?

// @ts-ignore
const req = require.context(
  "!!raw-loader!../../../assets/icons",
  true,
  /\.svg$/
);

const icons = req.keys().reduce((icons: any, filename: string) => {
  const id = filename.replace("./", "").replace(".svg", "");
  const svgString = req(filename).default;
  const SvgComponent = ({ className }: { className: string }) => (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: svgString }}
    />
  );
  return { ...icons, [id]: SvgComponent };
}, {});

interface IIconProps {
  name: string;
  className?: string;
  role?: string;
}

const Icon: FC<IIconProps> = ({ name, className, role }) => {
  const Icon = icons[name];
  if (!Icon) return null;
  return <Icon className={cn("icon", className)} role={role} />;
};

export default Icon;
