import classNames from "classnames";
import { ReactComponentPropsBase } from "../../../base-props/ReactComponentPropsBase";
import "./RichText.scss";
import styles from "./RichText.module.scss";
import useSelectTheme from "../../../utils/useSelectTheme";

export interface RichTextProps extends ReactComponentPropsBase {
  html: string;
  className?: string;
  refFunction?: (node: HTMLDivElement | null) => void;
  style?: React.CSSProperties;
}

const RichText: React.FC<RichTextProps> = ({
  html,
  className,
  refFunction = () => null,
  style,
}) => {
  const selectedTheme = useSelectTheme("", "mangfoldsprisLayout");
  return (
    <div
      className={classNames(styles.richText, className, styles[selectedTheme])}
      dangerouslySetInnerHTML={{ __html: html }}
      ref={node => refFunction(node)}
      style={style}
    />
  );
};

export default RichText;
