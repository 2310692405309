import Icon from "components/utils/Icon/Icon";
import styles from "./ImdiLogo.module.scss";
import useWindowSize from "src/utils/useWindowSize";

export interface ImdiLogoProps {}

const ImdiLogo: React.FC<ImdiLogoProps> = ({}) => {
  const isDesktop = useWindowSize().width > 768;

  return (
    <Icon
      className={styles.imdiLogo}
      name={isDesktop ? "imdi-logo-with-text" : "imdi-logo"}
    />
  );
};

export default ImdiLogo;
