import React, { useState, createContext, ReactNode } from "react";
import styles from "./Accordion.module.scss";
import classNames from "classnames";
import AccordionPanel from "./AccordionPanel";
import AccordionButton from "./AccordionButton";

export type AccordionContextProps = {
  isOpen: boolean;
  isClosed: boolean;
  toggleAccordion: () => void;
  setIsClosed: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AccordionContext = createContext<AccordionContextProps | null>(
  null
);

export type AccordionProps = {
  children?: ReactNode;
  className?: string;
  isGlobal?: boolean;
};

export const Accordion: React.FC<AccordionProps> = ({
  children,
  className,
  isGlobal,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isClosed, setIsClosed] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    if (!isOpen === true) {
      setIsClosed(false);
    }
  };

  return (
    <AccordionContext.Provider
      value={{ isOpen, isClosed, toggleAccordion, setIsClosed }}
    >
      <div
        data-accordion
        className={classNames(styles.accordion, className, {
          [styles.isActive]: isOpen && isGlobal,
        })}
      >
        {children}
      </div>
    </AccordionContext.Provider>
  );
};

export { AccordionPanel, AccordionButton };

export default Accordion;
