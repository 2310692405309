import React from "react";
import { ReactComponentPropsBase } from "../../base-props/ReactComponentPropsBase";
import BlockContainer from "components/layout/BlockContainer/BlockContainer";
import Button from "components/base/Button/Button";
import styles from "./NotFoundPage.module.scss";

export interface NotFoundPageProps extends ReactComponentPropsBase {
  heading: string;
  message: string;
  startPageButtonText: string;
}

const NotFoundPage: React.FC<NotFoundPageProps> = ({
  heading,
  message,
  startPageButtonText,
}) => {
  return (
    <main className={styles.errorPage}>
      <BlockContainer className={styles.blockContainer}>
        {heading && <h1>{heading}</h1>}
        {message && <p>{message}</p>}
        {startPageButtonText && (
          <Button>
            <a href="/">{startPageButtonText}</a>
          </Button>
        )}
      </BlockContainer>
    </main>
  );
};

export default NotFoundPage;
