import { createContext } from "react";

import RichTextBlock from "components/blocks/RichTextBlock/RichTextBlock";
import styles from "./AccordionBlock.module.scss";
import {
  Accordion,
  AccordionButton,
  AccordionPanel,
} from "../../../components/utils/Accordion/Accordion";
import { Icon } from "../../../NyINorge.Components";
import classNames from "classnames";
import { ContentAreaProps } from "components/utils/ContentArea/ContentArea";
import getId from "../../../utils/getId";
import useSelectTheme from "../../../utils/useSelectTheme";

export type AccordionBlockProps = {
  closeLabel?: string;
  text: ContentAreaProps;
  openLabel?: string;
  heading: string;
  theme?: "primary" | "secondary" | "blueSecondary";
};

type AccordionBlockContextProps = {
  accordionTheme?: "primary" | "secondary" | "blueSecondary";
};

export const AccordionBlockContext =
  createContext<AccordionBlockContextProps | null>(null);

const AccordionBlock: React.FC<AccordionBlockProps> = ({
  closeLabel = "close",
  text,
  openLabel = "open",
  heading,
  theme = "primary",
}) => {
  const accordionId = getId(heading);

  const mangfoldsprisTheme =
    theme === "blueSecondary" ? "blueSecondary" : "blue";

  const selectedTheme = useSelectTheme(theme, mangfoldsprisTheme);

  return (
    <AccordionBlockContext.Provider value={{ accordionTheme: theme }}>
      <Accordion
        className={classNames(styles.accordion, styles[selectedTheme])}
      >
        <AccordionButton
          className={styles.header}
          {...{ closeLabel, openLabel, id: accordionId }}
        >
          {(isOpen: boolean) => (
            <>
              <h3>{heading}</h3>
              <Icon
                className={classNames(styles.icon, {
                  [styles.isOpen]: isOpen,
                  asda: isOpen,
                })}
                name="chevron-down"
              />
            </>
          )}
        </AccordionButton>

        <AccordionPanel className={styles.panel} id={accordionId}>
          <RichTextBlock text={text} />
        </AccordionPanel>
      </Accordion>
    </AccordionBlockContext.Provider>
  );
};

export default AccordionBlock;
