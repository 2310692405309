import React, { useState, ReactNode, RefObject } from "react";
import classNames from "classnames";
import styles from "./Dropdown.module.scss";

import IconMS from "components/utils/IconMS/IconMS";
import useClickOutside from "../../../utils/useClickOutside";
import { useTheme } from "../../../utils/useTheme";
import useEscape from "../../../utils/useEscape";

export interface DropdownProps {
  buttonLabel?: string;
  labels?: {
    closeDropdownLabel: string;
    openDropdownLabel: string;
  };
  inHeader?: boolean;
  children?: ReactNode;
  className?: string;
  dropdownRef?: RefObject<HTMLDivElement>;
}

const Dropdown: React.FC<DropdownProps> = ({
  buttonLabel,
  labels = {
    closeDropdownLabel: "close",
    openDropdownLabel: "open",
  },
  inHeader,
  children,
  className,
  dropdownRef,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const theme = useTheme();

  const handleDropdownClick = () => {
    setShowDropdown(!showDropdown);
  };

  //@ts-ignore
  useClickOutside([dropdownRef], () => setShowDropdown(false));

  useEscape(() => setShowDropdown(false));

  return (
    <div
      className={classNames(styles.dropdown, styles[theme], className, {
        [styles.hasDarkBackground]: theme === "dark",
      })}
      ref={dropdownRef}
    >
      <button
        className={styles.dropdownLabel}
        onClick={handleDropdownClick}
        aria-label={
          showDropdown ? labels.closeDropdownLabel : labels.openDropdownLabel
        }
        aria-expanded={showDropdown}
        aria-haspopup="menu"
      >
        <span className={styles.buttonLabel}>{buttonLabel}</span>
        <IconMS
          name="expand_more"
          className={classNames(styles.chevronDown, {
            [styles.isOpen]: showDropdown,
          })}
          size="20px"
        />
      </button>
      {inHeader ? (
        <nav role="menu">
          <ul
            className={classNames(styles.dropdownContent, {
              [styles.showDropdownContent]: showDropdown,
            })}
          >
            {children}
          </ul>
        </nav>
      ) : (
        <ul
          className={classNames(styles.dropdownContent, {
            [styles.showDropdownContent]: showDropdown,
          })}
        >
          {children}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
