import { FC, CSSProperties, useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./IconMS.module.scss";
import FontFaceObserver from "fontfaceobserver";

interface IconMSProps {
  className?: string;
  name: string;
  size?: string;
  weight?: "100" | "200" | "300" | "400" | "500" | "600" | "700";
  grade?: number;
  opticalSize?: number;
  fill?: boolean;
}

const IconMS: FC<IconMSProps> = ({
  className,
  name,
  size,
  weight = "400",
  grade = 0,
  opticalSize = 20,
  fill = false,
}) => {
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const style = {
    "--icon-weight": weight,
    "--icon-grade": Math.max(-25, Math.min(grade, 200)),
    "--icon-optical-size": Math.max(20, Math.min(opticalSize, 48)),
    "--icon-fill": fill ? 1 : 0,
    "--icon-size": size,
  } as CSSProperties;

  useEffect(() => {
    const font = new FontFaceObserver("Material Symbols Sharp");
    font.load().then(() => {
      setFontsLoaded(true);
    });
  }, []);

  return (
    <span
      aria-hidden={true}
      className={classNames(
        "material-symbols-sharp",
        styles.iconMS,
        className,
        {
          [styles.fontsLoaded]: fontsLoaded,
        }
      )}
      style={style}
    >
      {name}
    </span>
  );
};

export default IconMS;
