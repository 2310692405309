import React, { FC } from "react";
import styles from "./Spinner.module.scss";
import classNames from "classnames";

interface SpinnerProps {
  className?: string;
}
const Spinner: FC<SpinnerProps> = ({ className }) => {
  return (
    <div className={classNames(styles.spinnerOverlay, className)}>
      <div className={styles.spinner}></div>
    </div>
  );
};

export default Spinner;
