import React, { useEffect, useState } from "react";

type SVGComponentProps = {
  svg: string;
  className?: string;
};

const SVGComponent: React.FC<SVGComponentProps> = ({ svg, className }) => {
  const [svgContent, setSvgContent] = useState("");

  useEffect(() => {
    const svgString =
      require(`!!raw-loader!../../../assets/images/${svg}.svg`).default;
    setSvgContent(svgString);
  }, [svg]);

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: svgContent }}
    />
  );
};

export default SVGComponent;
