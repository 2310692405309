import styles from "./StatisticsBlock.module.scss"

interface StatisticsBlockProps {
  regionCode: string;
  cardId: string;
  showAsGraph: boolean;
  hideFilters: boolean;
  hideTabs: boolean;
}

const StatisticsBlock = ({
  regionCode,
  cardId,
  showAsGraph,
  hideFilters,
  hideTabs,
}: StatisticsBlockProps) => {
  const scriptContent = JSON.stringify({
    regionCode: regionCode,
    cardId: cardId,
    isKPI: !showAsGraph,
    hideFilters: hideFilters,
    hideTabs: hideTabs,
  });
  return (
    <div data-imdikator="embed" className={styles.statisticsBlock}>
      <script type="application/json" dangerouslySetInnerHTML={{__html: scriptContent}}/>
    </div>
  );
};

export default StatisticsBlock;
