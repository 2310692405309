import { useContext } from "react";
import GridContext, { Breakpoints } from "./GridContext";
import styles from "./GridItem.module.scss";
import classNames from "classnames";
export interface GridItemStyleProps {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  padding?: number
  xsOffset?: number;
  smOffset?: number;
  mdOffset?: number;
  lgOffset?: number;
  xlOffset?: number;
}

interface GridItemProps extends GridItemStyleProps {
  children: React.ReactNode;
  className?: string;
}

const GridItem: React.FC<GridItemProps> = ({
  children,
  xs,
  sm,
  md,
  lg,
  xl,
  padding = 0,
  xsOffset = 0,
  smOffset = 0,
  mdOffset = 0,
  lgOffset = 0,
  xlOffset = 0,
  className,
}) => {
  const spacing = useContext(GridContext);
  const gutter = (breakpoint: keyof Breakpoints) =>
    typeof spacing === "number" ? spacing : spacing[breakpoint] || 0;

  const itemStyle = {
    "--xs-gutter": gutter("xs"),
    "--sm-gutter": gutter("sm") || gutter("xs"),
    "--md-gutter": gutter("md") || gutter("sm") || gutter("xs"),
    "--lg-gutter": gutter("lg") || gutter("md") || gutter("sm") || gutter("xs"),
    "--xl-gutter":
      gutter("xl") ||
      gutter("lg") ||
      gutter("md") ||
      gutter("sm") ||
      gutter("xs"),
    "--padding": padding,
    "--xs-width": xs,
    "--sm-width": sm || xs,
    "--md-width": md || sm || xs,
    "--lg-width": lg || md || sm || xs,
    "--xl-width": xl || lg || md || sm || xs,
    "--xs-offset": xsOffset,
    "--sm-offset": smOffset || xsOffset,
    "--md-offset": mdOffset || smOffset || xsOffset,
    "--lg-offset": lgOffset || mdOffset || smOffset || xsOffset,
    "--xl-offset": xlOffset || lgOffset || mdOffset || smOffset || xsOffset,
  };

  return (
    <div className={classNames(styles.gridItem, className)} style={itemStyle}>
      {children}
    </div>
  );
};

export default GridItem;
