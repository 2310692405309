import { ReactNode } from "react";
import styles from "./BlockContainer.module.scss";
import classNames from "classnames";

export interface BlockContainerProps {
  children: ReactNode;
  className?: string;
  fullWidth?: boolean;
  verticalPadding?: boolean;
}

const BlockContainer: React.FC<BlockContainerProps> = ({
  children,
  fullWidth,
  className,
  verticalPadding,
  ...props
}) => {
  return (
    <div
      {...props}
      className={classNames(styles.blockContainer, className, {
        [styles.fullWidth]: fullWidth,
        [styles.verticalPadding]: verticalPadding,
      })}
    >
      {children}
    </div>
  );
};

export default BlockContainer;
