import { ReactComponentPropsBase } from "../../../base-props/ReactComponentPropsBase";
import SearchResults, {
  SearchResultsProps,
} from "./SearchResults/SearchResults";
import SearchInput, { SearchInputProps } from "./SearchInput/SearchInput";
import BlockContainer from "components/layout/BlockContainer/BlockContainer";
import { useState } from "react";
import styles from "./SearchBlock.module.scss";
import Spinner from "components/base/Spinner/Spinner";
import { fetchResults } from "./fetchSearch";
import { useSite } from "../../../utils/useSite";

export interface SearchBlockProps extends ReactComponentPropsBase {
  searchInput?: SearchInputProps;
  searchResults?: SearchResultsProps;
  totalCount?: number;
  endpoint?: string;
  itemsPerPage?: number;
  query?: string;
  pageNumber?: number;
}

const SearchBlock: React.FC<SearchBlockProps> = ({
  searchInput,
  searchResults,
  endpoint,
  itemsPerPage = 10,
  query = "",
  totalCount,
  pageNumber,
}) => {
  const site = useSite();
  const [isLoading, setIsLoading] = useState(false);
  const [searchInputState, setSearchInputState] = useState<
    SearchInputProps | undefined
  >(searchInput);
  const [searchResultsState, setSearchResultsState] = useState<
    SearchResultsProps | undefined
  >(searchResults);
  const [queryLogState, setQueryLogState] = useState<string | undefined>();
  const [prevQuery, setPrevQuery] = useState<string>(query);

  const [totalCountState, setTotalCountState] = useState(totalCount || 0);
  const [page, setPage] = useState(pageNumber || 1);
  const [resultsShown, setResultsShown] = useState(
    searchResults?.results?.length || 0
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const { loadMoreText } = { ...searchResults };

  const handleError = (errorMessage: string) => {
    // Reset states
    setSearchInputState({
      ...searchInputState,
      searchHitInfo: undefined,
    });
    setSearchResultsState({
      ...searchResultsState,
      results: undefined,
    });
    setTotalCountState(0);
    setPage(1);
    setResultsShown(0);
    setErrorMessage(errorMessage);
  };

  const attemptFetch = async (query: string, page?: number) => {
    setIsLoading(true);

    let result;
    try {
      result = await fetchResults(query, site, itemsPerPage, page, endpoint);
    } catch (e: any) {
      handleError(e.message);
      setIsLoading(false);
      return;
    }
    errorMessage && setErrorMessage(undefined);
    setIsLoading(false);
    return result;
  };

  const search = async (query: string) => {
    const rawResult = (await attemptFetch(query)) as SearchResultsProps;

    const { loadMoreText, ...result } = rawResult;

    setSearchResultsState({
      ...result,
      results: result?.results,
    });

    let queryLog = "";
    //@ts-ignore
    if (typeof CludoSearch != "undefined") {
      //@ts-ignore
      queryLog = CludoSearch.trackQuery(
        result?.query,
        result?.queryId,
        result?.totalCount,
        result?.pageNumber,
        result?.referralUrl,
        "", // referral page title
        0, // response time
        result?.bannerIds
      );
    }

    setQueryLogState(queryLog);
    setPrevQuery(result?.query || "");
    setPage(1);
    setResultsShown(result?.results?.length || 0);
    setTotalCountState(result?.totalCount || 0);
  };

  const loadMore = async () => {
    const result = await attemptFetch(prevQuery, page + 1);

    setSearchResultsState({
      ...searchResultsState,
      results: [
        ...(searchResultsState?.results ? searchResultsState.results : []),
        ...(result?.results ? result.results : []),
      ],
    });
    setResultsShown(resultsShown + (result?.results?.length || 0));
    setPage(page + 1);
  };

  return (
    <BlockContainer className={styles.searchBlock}>
      {searchInput && (
        <SearchInput
          {...searchInputState}
          totalCount={totalCountState}
          searchWord={prevQuery}
          search={search}
        />
      )}

      {isLoading && <Spinner />}
      {searchResults && (
        <SearchResults
          {...searchResultsState}
          loadMoreText={loadMoreText}
          loadMore={loadMore}
          showLoadMore={resultsShown < totalCountState}
          errorMessage={errorMessage}
          queryLog={queryLogState}
        />
      )}
    </BlockContainer>
  );
};

export default SearchBlock;
