import React from "react";

// Define types for breakpoints
export interface Breakpoints {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
}

// Define a type for the spacing prop that can either be a number or an object with breakpoints
export type Spacing =
  | number
  | { xs?: number; sm?: number; md?: number; lg?: number; xl?: number };

const breakpoints: Breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

// Create a React context for spacing with TypeScript
const GridContext = React.createContext<Spacing>(0);

export default GridContext;
