import classNames from "classnames";
import Icon from "../../utils/Icon/Icon";
import styles from "./LinkListBlock.module.scss";
import useSelectTheme from "../../../utils/useSelectTheme";
import MouseLink from "components/utils/MouseLink/MouseLink";
import { LinkProps } from "src/base-props/LinkProps";

export interface LinkListBlockProps {
  linkListItems: LinkProps[];
}

const LinkListBlock: React.FC<LinkListBlockProps> = ({ linkListItems }) => {
  const selectedTheme = useSelectTheme("", "blue");
  if (linkListItems?.length > 0) {
    return (
      <ul className={classNames(styles.linkList, styles[selectedTheme])}>
        {linkListItems.map(item =>
          item.text && item.url ? (
            <li key={item.text}>
              <MouseLink className={styles.mouseLink} url={item.url}>
                <div className={styles.label}>
                  <a target={item.target} href={item.url}>
                    {item.text}
                  </a>
                  {item.externalLinkDomain ? (
                    <Icon
                      className={styles.icon}
                      name="external-arrow"
                      aria-hidden
                    />
                  ) : (
                    <Icon
                      className={styles.icon}
                      name="arrow-right"
                      aria-hidden
                    />
                  )}
                </div>
                {item.externalLinkDomain && (
                  <span>{item.externalLinkDomain}</span>
                )}
              </MouseLink>
            </li>
          ) : null
        )}
      </ul>
    );
  } else {
    return null;
  }
};

export default LinkListBlock;
