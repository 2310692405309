import React from "react";
import styles from "./NyINorgeSearchPage.module.scss";
import HeroBlock, {
  HeroBlockProps,
} from "components/blocks/HeroBlock/HeroBlock";
import { ReactComponentPropsBase } from "src/base-props/ReactComponentPropsBase";
import SearchBlock from "components/blocks/SearchBlock/SearchBlock";
import { SearchInputProps } from "components/blocks/SearchBlock/SearchInput/SearchInput";
import { SearchResultsProps } from "components/blocks/SearchBlock/SearchResults/SearchResults";

export interface NyINorgeSearchPageProps extends ReactComponentPropsBase {
  hero?: HeroBlockProps;
  search?: {
    searchInput?: SearchInputProps;
    searchResults?: SearchResultsProps;
  };
}

const NyINorgeSearchPage: React.FC<NyINorgeSearchPageProps> = ({
  hero,
  search,
}) => {
  return (
    <main className={styles.nyINorgeSearchPage}>
      {hero && <HeroBlock {...hero} />}
      {search && <SearchBlock {...search} />}
    </main>
  );
};

export default NyINorgeSearchPage;
