import styles from "./LinkItemWIthIcon.module.scss";
import Icon from "../../utils/Icon/Icon";
import { LinkProps } from "src/base-props/LinkProps";

export interface LinkItemWithIconProps extends LinkProps {
  icon: string;
  label: string;
}

const LinkItemWithIcon: React.FC<LinkItemWithIconProps> = ({
  icon,
  label,
  target,
  text,
  url,
}) => {
  if (text && url) {
    return (
      <li key={text} className={styles.listItem}>
        <a href={url} target={target}>
          <div className={styles.titleContainer}>
            <Icon name={icon} className={styles.icon} />
            <h3>{text}</h3>
          </div>
          <p>{label}</p>
        </a>
      </li>
    );
  }
  return null;
};

export default LinkItemWithIcon;
