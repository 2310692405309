import React, { useEffect, useState } from "react";
import styles from "./DividerBlock.module.scss";
import getRandom from "../../../utils/getRandom";
import Icon from "../../../components/utils/Icon/Icon";
import BlockContainer from "components/layout/BlockContainer/BlockContainer";

const illustrations = [
  "mika-motiver/motiv-1-1",
  "mika-motiver/motiv-1-2",
  "mika-motiver/motiv-1-3",
  "mika-motiver/motiv-2-1",
  "mika-motiver/motiv-2-2",
  "mika-motiver/motiv-2-3",
  "mika-motiver/motiv-3-1",
  "mika-motiver/motiv-3-2",
  "mika-motiver/motiv-3-3",
];

const DividerBlock: React.FC = () => {
  const [randomIllustration, setRandomIllustration] = useState("");

  useEffect(() => {
    setRandomIllustration(getRandom(illustrations));
  }, []);

  return (
    <BlockContainer className={styles.dividerBlock}>
      <div aria-hidden="true" className={styles.illustration}>
        <Icon name={randomIllustration} />
      </div>
    </BlockContainer>
  );
};

export default DividerBlock;
