import { useCallback, useEffect, useState } from "react";
import { setTabIndexes } from "./setTabIndexes";

/*
 * This hook is used to expand and collapse a section of text
 * when a button is clicked. It also sets the tabindex of links in the
 * text to -1 when collapsed and 0 when expanded.
 */
const useExpand = (
  onExpand: (expandedHeight: number) => void,
  onClose?: (minimizedHeight: number) => void
) => {
  const [minimizedHeight, setMinimizedHeight] = useState<undefined | number>(
    undefined
  );
  const [expandedHeight, setExpandedHeight] = useState<undefined | number>(
    undefined
  );
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (isExpanded) {
      expandedHeight && onExpand(expandedHeight);
    } else {
      onClose && onClose(minimizedHeight || 0);
    }
  }, [isExpanded, expandedHeight, minimizedHeight]);

  const textRefFunction = useCallback(
    (node: HTMLDivElement | null) => {
      // This is ran whenever the the node with this ref is rendered
      if (!node) return;
      setTabIndexes(node, "-1");
      if (minimizedHeight === undefined) setMinimizedHeight(node.offsetHeight);
      const scrollHeight = node.scrollHeight || 0;
      if (isExpanded) {
        setTabIndexes(node, "0");
        setExpandedHeight(scrollHeight);
        const links = node.querySelectorAll("a");
        if (links.length > 0) {
          links[0].focus();
        }
      }
    },
    [isExpanded]
  );

  return { textRefFunction, isExpanded, setIsExpanded };
};

export default useExpand;
