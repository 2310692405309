import { useEffect } from "react";

const fixedElementsById = ["header-fixed"];

const useDisableScroll = (shouldDisable: boolean) => {
  useEffect(() => {
    toggleFakeScrollbarWidths(shouldDisable);

    document.body.style.overflow = shouldDisable ? "hidden" : "auto";

    return () => {
      document.body.style.overflow = "auto";
      toggleFakeScrollbarWidths(false);
    };
  }, [shouldDisable]);
};

function toggleFakeScrollbarWidths(enable: boolean) {
  const scrollbarWidth =
    window.innerWidth - document.documentElement.clientWidth;

  const elements = [
    document.body,
    ...fixedElementsById.map(id => document.getElementById(id)),
  ];

  elements.forEach((element: HTMLElement | null) => {
    element &&
      element.style.setProperty(
        "padding-right",
        enable ? scrollbarWidth + "px" : "0px",
        "important"
      );
  });
}

export default useDisableScroll;
