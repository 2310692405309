import React, { RefObject } from "react";
import classNames from "classnames";
import { MenuProps } from "./Menu";
import Dropdown from "components/base/Dropdown/Dropdown";
import IconMS from "../../utils/IconMS/IconMS";
import styles from "./NavBar.module.scss";
import { LinkProps } from "src/base-props/LinkProps";

export interface NavBarProps {
  handleHamburgerClick: () => void;
  isOpen?: boolean;
  loginLink?: LinkProps;
  menuLabel?: string;
  navigation?: MenuProps;
  searchPageLink?: LinkProps;
  site?: string;
  theme?: string;
  dropdownRef?: RefObject<HTMLDivElement>;
}

const NavBar: React.FC<NavBarProps> = ({
  handleHamburgerClick,
  isOpen,
  loginLink,
  menuLabel,
  navigation,
  searchPageLink,
  theme = "white",
  dropdownRef,
}) => {
  return (
    <div
      className={classNames(styles.navBar, {
        [styles.hasDarkBackground]:
          ["green", "blue", "dark"].includes(theme) && !isOpen,
      })}
    >
      {loginLink?.url && loginLink?.text && (
        <a
          target={loginLink.target}
          href={loginLink.url}
          className={styles.loginLink}
        >
          <IconMS name="lock_open" />
          <span className={styles.linkText}>{loginLink.text}</span>
        </a>
      )}
      {searchPageLink?.url && searchPageLink?.text && (
        <a
          target={searchPageLink.target}
          href={searchPageLink.url}
          className={styles.searchPageLink}
          aria-label={searchPageLink.text}
        >
          <IconMS name="search" />
          <span className={styles.linkText}>{searchPageLink.text}</span>
        </a>
      )}
      {navigation?.languages && (
        <div className={styles.dropdownContainer}>
          <IconMS name="language" />
          <Dropdown
            buttonLabel={navigation.languages.currentLanguage}
            labels={{
              closeDropdownLabel: navigation.languages.currentLanguage,
              openDropdownLabel: navigation.languages.currentLanguage,
            }}
            className={styles.dropDown}
            inHeader={true}
            dropdownRef={dropdownRef}
          >
            {navigation.languages.availableLanguages.map(languages => (
              <li key={languages.languageName}>
                <a className={styles.text} href={languages.currentPageUrl}>
                  {languages.languageName}
                </a>
              </li>
            ))}
          </Dropdown>
        </div>
      )}
      <button className={styles.hamburger} onClick={handleHamburgerClick}>
        <div className={styles.hamburgerIcon}>
          <IconMS name={isOpen ? "close" : "menu"} />
        </div>
        <span className={styles.text}>
          {isOpen ? navigation?.labels?.closeMenuLabel : menuLabel}
        </span>
      </button>
    </div>
  );
};

export default NavBar;
