import { SearchResponse } from "./SearchInput/SearchInput";

export const fetchResults = async (
  query: string,
  site: string,
  itemsPerPage?: number,
  page?: number,
  endpoint?: string,
) => {
  if (!endpoint) throw new Error("No endpoint provided");

  const { apiUrl, params } = createUrl(
    endpoint,
    query,
    site,
    itemsPerPage,
    page,
  );

  updateBrowserUrl(params);

  const results = await fetch(apiUrl).catch(e => {
    throw new Error(e.message);
  });

  if (!results?.ok) {
    throw new Error(results.statusText);
  }

  const response: SearchResponse = await results.json();

  return response;
};

export function createUrl(
  endpoint: string,
  query: string,
  site: string,
  itemsPerPage?: number,
  page?: number,
) {
  const baseUrl = `${window.location.origin}${window.location.pathname}`
  const apiUrl = new URL(endpoint, baseUrl);
  const params = new URLSearchParams();
  params.append("q", query);
  if (itemsPerPage) params.append("itemsPerPage", itemsPerPage.toString());
  if (page) params.append("page", page.toString());
  params.append("site", site);


  apiUrl.search = params.toString();
  return { apiUrl: apiUrl.toString(), params };
}

function updateBrowserUrl(params: URLSearchParams) {
  // Update browser URL without triggering a full page reload
  const newBrowserUrl = new URL(window.location.href);
  newBrowserUrl.search = params.toString();
  window.history.pushState({}, "", newBrowserUrl.toString());
}
