import Media from "components/utils/Media/Media";
import styles from "./FeaturedContentBlock.module.scss";
import classNames from "classnames";
import { ImageProps } from "components/base/Image/Image";
import { VideoProps } from "components/shared/Video/Video";
import CTA, { CTAProps } from "components/base/CTA/CTA";
import OverrideTheme from "components/utils/OverrideTheme/OverrideTheme";

export interface FeaturedContentBlockProps {
  video?: VideoProps;
  image?: ImageProps;
  heading: string;
  subheading?: string;
  text: string;
  primaryLink: CTAProps;
  secondaryLink?: CTAProps;
  theme?: "bold" | "vivid" | "moderate" | "soft";
  oneThirdContent?: boolean;
  imageOnRight?: boolean;
  showVideoMarker?: boolean;
}

const FeaturedContentBlock: React.FC<FeaturedContentBlockProps> = ({
  video,
  image,
  heading,
  subheading,
  text,
  primaryLink,
  secondaryLink,
  theme = "bold",
  oneThirdContent,
  imageOnRight,
  showVideoMarker,
}) => {
  const overridingTheme =
    theme === "vivid" || theme === "bold" ? "dark" : "light";

  return (
    <div
      className={classNames(styles.featuredContentBlock, {
        [styles.imageOnRight]: imageOnRight,
        [styles[theme]]: theme,
      })}
    >
      <div className={classNames(styles.mediaContainer)}>
        {showVideoMarker && video && (
          <div className={styles.videoMarker}>Video</div>
        )}
        {(video || image) && (
          <Media className={styles.media} video={video} image={image} />
        )}
      </div>

      <div
        className={styles.textContent}
        // @ts-ignore
        style={{ "--width": oneThirdContent ? "1/3" : "1/2" }}
      >
        <h3 className={styles.subheading}>{subheading}</h3>
        <h2 className={styles.heading}>{heading}</h2>
        <p className={styles.text}>{text}</p>
        <OverrideTheme theme={overridingTheme}>
          <div className={styles.linksContainer}>
            {primaryLink && <CTA {...primaryLink} />}
            {secondaryLink && <CTA {...secondaryLink} theme="tertiary" />}
          </div>
        </OverrideTheme>
      </div>
    </div>
  );
};

export default FeaturedContentBlock;
