import React, { useState } from "react";

import styles from "./Video.module.scss";
import VideoPlayer from "./VideoPlayer";
import Image, { ImageProps } from "../../base/Image/Image";
import IconMS from "../../utils/IconMS/IconMS";
import VideoThumbnail from "./VideoThumbnail";
import Modal from "components/utils/Modal/Modal";

export interface VideoProps {
  url: string;
  playLabel: string;
  image?: ImageProps;
  description?: string;
}

const Video: React.FC<VideoProps> = ({
  url,
  image,
  playLabel,
  description,
}) => {
  const [showPlayer, setShowPlayer] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);

  const handlePlayButtonClick = () => {
    setShowPlayer(true);
  };

  const handleModalClose = () => {
    setShowPlayer(false);
    setIsVideoReady(false);
  };

  return (
    <div className={styles.video}>
      {showPlayer && (
        <Modal
          isOpen={showPlayer}
          isVisible={isVideoReady}
          onClose={handleModalClose}
        >
          <div className={styles.playerInModal}>
            <VideoPlayer
              url={url}
              onLoad={() => setIsVideoReady(true)}
              autoPlay
            />
          </div>
        </Modal>
      )}
      <button
        className={styles.overlayButton}
        onClick={handlePlayButtonClick}
        aria-label={playLabel}
      >
        {image ? (
          <Image
            className={styles.image}
            {...image}
            sizes={{
              sm: 350,
              md: 430,
              lg: 450,
              xl: 650,
            }}
          />
        ) : (
          <div className={styles.playerOutsideModal}>
            <VideoThumbnail url={url} />
          </div>
        )}
        <div className={styles.playIcon}>
          <IconMS name="play_arrow" fill size="48px" />
        </div>
      </button>

      {description && <p className={styles.description}>{description}</p>}
    </div>
  );
};

export default Video;
