import styles from "./AccordionItemBlock.module.scss";
import {
  Accordion,
  AccordionButton,
  AccordionPanel,
} from "../../../components/utils/Accordion/Accordion";
import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";
import IconMS from "src/components/utils/IconMS/IconMS";
import CTA, { CTAProps } from "components/base/CTA/CTA";
import classNames from "classnames";
import getId from "../../../utils/getId";

export interface AccordionItemBlockProps {
  closeLabel?: string;
  heading: string;
  linkButton?: CTAProps;
  openLabel?: string;
  text: ContentAreaProps;
}

const AccordionItemBlock: React.FC<AccordionItemBlockProps> = ({
  closeLabel = "close",
  heading,
  linkButton,
  openLabel = "open",
  text,
}) => {
  const accordionId = getId(heading);

  return (
    <Accordion className={styles.accordionItemBlock} isGlobal={true}>
      <AccordionButton
        className={classNames(styles.header)}
        {...{ closeLabel, openLabel, id: accordionId, isGlobal: true }}
      >
        {(isOpen: boolean) => (
          <>
            <IconMS
              name="expand_more"
              className={classNames(styles.icon, {
                [styles.isOpen]: isOpen,
              })}
            />
            <h3>{heading}</h3>
          </>
        )}
      </AccordionButton>

      <AccordionPanel className={styles.panel} id={accordionId}>
        <div className={styles.richText}>
          {text && <ContentArea isInsideBlock {...text} />}
        </div>

        {linkButton && (
          <CTA
            className={styles.linkButton}
            size="small"
            theme="primary"
            {...linkButton}
          />
        )}
      </AccordionPanel>
    </Accordion>
  );
};

export default AccordionItemBlock;
