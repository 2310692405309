import React from "react";
import { ReactComponentPropsBase } from "../../base-props/ReactComponentPropsBase";
import ContentArea, {
  ContentAreaProps,
} from "components/utils/ContentArea/ContentArea";

export interface InProStartPageProps extends ReactComponentPropsBase {
  contentArea: ContentAreaProps;
}

const InProStartPage: React.FC<InProStartPageProps> = ({ contentArea }) => {
  return (
    <main className="inproStartPage">
      {contentArea && <ContentArea {...contentArea} />}
    </main>
  );
};

export default InProStartPage;
